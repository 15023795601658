import { Icon } from '/features/buildingBlocks/Icon'
import { HeadingMd } from '/features/buildingBlocks/Heading'
import { ButtonPrimary } from '/features/buildingBlocks/Button'
import { useTranslate } from '/machinery/I18n'
import { RewardCalculatorPortalFlow } from '/features/buildingBlocks/reward-calculator/RewardCalculatorPortalFlow'
import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'
import { routeMap } from '/routeMap'

import iconMoney from '/images/icons/stapel-muntjes.raw.svg'
import styles from './RewardCalculatorCta.css'

export function RewardCalculatorCta({ title, link }) {
  const { __ } = useTranslate()
  const [showRewardCaculatorPortal, setShowRewardCaculatorPortal] = React.useState(false)

  const benefitsPageRef = link?.ref && {
    url: determineDocumentPathSync({ document: link.ref, routeMap }),
    label: link.label
  }

  return (
    <div className={styles.component}>
      <div className={styles.cta}>
        <div className={styles.inner}>
          <Icon icon={iconMoney} />
          <div className={styles.content}>
            {title && (
              <HeadingMd layoutClassName={styles.headingLayout} h={3} {...{ title }} />
            )}
            <ButtonPrimary
              dataX='open-reward-calculator'
              label={__`calculate-your-salary`}
              onClick={() => setShowRewardCaculatorPortal(true)}
            />
          </div>
        </div>
      </div>
      <RewardCalculatorPortalFlow
        onChange={setShowRewardCaculatorPortal}
        isOpen={showRewardCaculatorPortal}
        link={benefitsPageRef}
      />
    </div>
  )
}
